import type { NextPage } from "next";

import ResourceView from "@components/ResourceView/ResourceView";
import { IPageData } from "@components/ResourceView/types";
import Meta from "@components/seo/Meta";
import { TConfig } from "@features/config/types";
import { IPage } from "features/cms/types";

interface ICustomPageProps {
  page: IPage;
  pageData: IPageData;
  url?: string;
  config: TConfig;
}

const CustomPage: NextPage<ICustomPageProps> = ({ page, pageData, url, config }) => {
  const meta = {
    title: page.title,
    description: page.metaDescription,
    keywords: page.metaKeywords,
    canonicalUrl: pageData?.blogData?.canonicalUrl,
    noIndex: page.noIndex,
  };

  return (
    <>
      <Meta {...meta} />
      <ResourceView key={url} resource={page} pageData={pageData} config={config} />
    </>
  );
};

export default CustomPage;
